import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import {
  Container,
  Resources,
  deviceMin,
  SubmitBtn
} from "../components/Primitives"
import meta from "../../content/data/meta.json"
import ResourceHero from "../components/Resources/resourceHero"
import { deviceMax } from "../components/Primitives"
import styled from "styled-components"
import { useState } from "react"
import emailjs from "emailjs-com"
import Swal from "sweetalert2"
import contact from "../../static/images/c-us.webp"

const ContactPage = ({ data }) => {
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const handleFirstNameChange = event => {
    setfirstName(event.currentTarget.value)
  }
  const handleLastNamehange = event => {
    setlastName(event.currentTarget.value)
  }
  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const handleSubjectChange = event => {
    setSubject(event.currentTarget.value)
  }

  const handleMessageChange = event => {
    setMessage(event.currentTarget.value)
  }

  function sendFeedback(templateId, variables) {
    emailjs
      .send("gmail", templateId, variables, "user_bQxczeMAkeLROJb3xebPP")
      .then(res => {
        console.log("Email successfully sent!")
        Swal.fire("Thanks!", "We will get back to you soon", "success")
      })
      .catch(err => {
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occurred:",
          err
        )
        Swal.fire(
          "Oopss...",
          "Looks like you missed some thing, try submitting another form",
          "error"
        )
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const templateId = "template_owdk6X9p"
    setfirstName("")
    setlastName("")
    setEmail("")
    setSubject("")
    setMessage("")

    sendFeedback(templateId, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      subject: subject,
      message: message
    })
  }

  return (
    <Layout>
      <SEO
        pathName='/contact'
        title='Contact'
        // keywords={keywords}
      />
      <ResourceHero
        color="RGBA(184, 133, 142, 0.7)"
        title="Contact Us"
        subTitle="We take the time to develop strong working relationships with our clients by understanding their business, their needs, and the company culture. With that understanding and with the knowledge and expertise gained from over the years of experience in human resources and business, we recommend solutions that align with business priorities and then support clients in implementation"
        image={contact}
      />

      <Body>
        <H1_Attn id="about">Leave us a message</H1_Attn>
        <form className="was-validated" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Enter first name"
                value={firstName}
                onChange={handleFirstNameChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="email">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Enter last name"
                value={lastName}
                onChange={handleLastNamehange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputAddress">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email address"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputAddress">Subject</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="Enter subject"
                value={subject}
                onChange={handleSubjectChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              placeholder="Type your message here"
              value={message}
              onChange={handleMessageChange}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
              marginBottom: "10%"
            }}
          >
            <SubmitBtn
              style={{
                background: " #85083E",
                color: "white",
                padding: "1% 5%"
              }}
              type="submit"
              className="btn "
            >
              <b>Send</b>
            </SubmitBtn>
          </div>
        </form>
      </Body>
    </Layout>
  )
}

export default ContactPage

const H1_Attn = styled.h1`
  text-align: center;
  color: #85083e;
  margin: 50px 0;
  text-transform: uppercase;
`
const Body = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`
const Btn = styled.button`
  background: var(--softRed);
`
